import { useState, type FC, type PropsWithChildren } from 'react';
import { useLoaderData } from 'react-router-dom';
import { Button } from 'flowbite-react';
import { Helmet } from 'react-helmet';
import { HiOutlinePlus } from 'react-icons/hi';
import { type AnalyticsResponse } from 'domains/api/getAnalytics';
import { usePageTracking } from 'utils/ga';
import AnalyticRegistrationModal from 'components/molecules/Analytics/AnalyticRegistrationModal';
import AnalyticsTable from 'components/molecules/Analytics/AnalyticsTable';

const Analytics: FC<PropsWithChildren> = () => {
  usePageTracking(); // ページビュー計測
  const data = useLoaderData() as AnalyticsResponse;
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Helmet>
        <title>アナリティクス一覧: Growth Support</title>
      </Helmet>
      <div className="border-b py-3 pl-2 flex justify-between items-center">
        <h1 className="text-xl leading-none tracking-tight text-gray-900 md:text-xl dark:text-white">
          アナリティクス一覧
        </h1>
        <Button
          onClick={() => {
            setOpenModal(true);
          }}
          color="light"
        >
          <HiOutlinePlus className="mr-1 h-4 w-4" />
          <span>新規登録</span>
        </Button>
        <AnalyticRegistrationModal
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      </div>
      <div className="overflow-x-auto py-5">
        <AnalyticsTable analyticsData={data} />
      </div>
    </>
  );
};

export default Analytics;
